import React, { useState } from 'react';
import { Snackbar } from '@material-ui/core';
import * as Axios from 'axios';


import SECRETS from './secrets';
import logo from './logo_2.svg';

const axios = Axios.create({
  baseURL: SECRETS.SERVER,
});

const EMAILREGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const SIDEMARGIN = '30px';

const bg = {
  maxWidth: '450px',
  height: '100%',
  width: '100%',
  backgroundColor: '#ca8ec4',
  background: 'url(catedral-bg-2.png) center/135% no-repeat',
};

const container = {
  maxWidth: '450px',
  height: '100%',
  width: '100%',
  background: 'rgba(0,0,0,0.4)',
  color: '#fff',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  fontFamily: '"Raleway", sans-serif',
};

const inputHeader = {
  color: '#848484',
  width: '80%',
  marginTop: '15px',
  fontSize: '18px',
};

const inputBox = {
  backgroundColor: '#fff',
  color: '#444',
  borderRadius: '15px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  margin: `0 ${SIDEMARGIN}`,
};

const inputStyle = {
  height: '40px',
  width: '80%',
  margin: `5px ${SIDEMARGIN} 25px ${SIDEMARGIN}`,
  borderTop: '0',
  borderLeft: '0',
  borderRight: '0',
  borderBottom: '1px solid #454545',
};

const buttonStyle = {
  // backgroundColor: '#e83479',
  backgroundColor: '#f4822a',
  boxShadow: '0px 6px 0px #d1600a',
  color: 'white',
  borderRadius: '10px',
  fontSize: '24px',
  textAlign: 'center',
  height: '55px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: `0 ${SIDEMARGIN}`,
};

const welcome = {
  fontSize: '38px',
  fontWeight: 'bold',
  alignSelf: 'center',
  textShadow: '#000 2px 2px 20px',
};

const header = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignSelf: 'center',
};

const footer = {
  display: 'flex',
  alignSelf: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  lineHeight: '40px',
};

const textCenter = {
  textAlign: 'center',
};

const logoStyle = {
  width: '200px',
  height: '100%',
};

const textUnderline = {
  textDecoration: 'underline',
};

const privacyText = {
  textDecoration: 'none',
};

const whiteText = {
  color: '#fff',
};

const WelcomeScreen = ({ setScreen, setToken, setOpenInfo }) => {
  const [correo, setCorreo] = useState('');
  const [pass, setPass] = useState('');
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState('');

  const handleClose = () => setOpen(false);

  const validate = () => {
    if (!EMAILREGEX.test(correo)) {
      setMsg('Ingresa un correo válido');
      setOpen(true);
      return false;
    }
    if (pass.length <= 0) {
      setMsg('Ingresar una contraseña');
      setOpen(true);
      return false;
    }
    return true;
  };

  const login = async () => {
    if (validate()) {
      axios.defaults.baseURL = SECRETS.SERVER;
      const res = await axios
        .post('/login', {
          correo,
          pass,
        })
        .catch((e) => {
          console.error(e);
          if (e.response.data === 'correo no validado') {
            setMsg('Su correo no ha sido verificado, por favor revise su correo para verificarlo.');
            setOpen(true);
            return;
          }
          setMsg('El usuario o contraseña son incorrectos.');
          setOpen(true);
          return;
        });
        // save localStorage
      if (res && res.data) {
        console.log(res.data);
        setToken(res.data);
      }
    }
  };

  return (
    <div style={bg}>
      <div style={container}>
        <div style={header}>
          <img
            src={logo}
            alt="logo"
            style={logoStyle}
          />
        </div>
        <div style={welcome}>Bienvenida</div>
        <div style={inputBox}>
          <div style={inputHeader}>Correo</div>
          <input
            style={inputStyle}
            value={correo}
            type="email"
            onChange={(e) => setCorreo(e.target.value)}
          />
        </div>
        <div style={inputBox}>
          <div style={inputHeader}>Contraseña</div>
          <input
            style={inputStyle}
            value={pass}
            type="password"
            onChange={(e) => setPass(e.target.value)}
          />
        </div>
        <div
          style={buttonStyle}
          onClick={login}
        >
          Iniciar sesión
        </div>
        <div style={footer}>
          <div
            style={{ ...textCenter, ...textUnderline }}
            onClick={() => setScreen(1)}
          >
            ¿Aún no tienes cuénta? Regístrate
          </div>
          <div style={{ ...textCenter, ...textUnderline }} onClick={() => setOpenInfo(true)}>
            ¿Cómo funciona?
          </div>
          <div>
            <span style={privacyText}>Al usar ésta aplicación acepta los </span>
            <a href="terminos-condiciones.pdf" style={whiteText} target="_blank" rel="noopener noreferrer">
              términos y condiciones del servicio
            </a>
            <span style={privacyText}> y el </span>
            <a href="aviso-privacidad.pdf" style={whiteText} target="_blank" rel="noopener noreferrer">
              aviso de privacidad
            </a>
            .
          </div>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={msg}
      />
    </div>
  )
};

export default WelcomeScreen;