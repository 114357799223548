import React, { useState } from 'react';
import { Snackbar } from '@material-ui/core';
import * as Axios from 'axios';


import SECRETS from './secrets';

import back from './back.svg';

const axios = Axios.create({
  baseURL: SECRETS.SERVER,
});

const EMAILREGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const container = {
  height: '100%',
  width: '100%',
  backgroundColor: '#fff',
  color: '#848484',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const flexColumn = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
};

const phase1 = {
  ...flexColumn,
  height: '100%',
  width: '80%',
};

const phase2 = {
  ...flexColumn,
  height: '100%',
  width: '80%',
};

const inputStyle = {
  borderTop: '0',
  borderRight: '0',
  borderLeft: '0',
  borderBottom: '1 solid #454545',
  height: '50px',
  width: '100%',
  margin: '10px 0',
};

const buttonStyle = {
  backgroundColor: '#f4822a',
  color: 'white',
  borderRadius: '10px',
  fontSize: '20px',
  textAlign: 'center',
  height: '55px',
  minHeight: '55px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  // margin: `0 ${SIDEMARGIN}`,
  boxShadow: '0px 6px 0px #d1600a',
  width: '100%',
  cursor: 'pointer',
};

const header = {
  fontSize: '34px',
  fontWeight: 'bold',
  alignSelf: 'center',
  textAlign: 'center',
  paddingTop: '40px',
  color: '#454545',
};

const footer = {
  fontSize: '14px',
  textDecoration: 'underline',
  paddingBottom: '30px',
  paddingTop: '40px',
};

const miniContainer = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
};

const bottomContainer = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
};

const backStyle = {
  position: 'absolute',
  top: '20px',
  left: '15px',
  zIndex: 1,
  width: '25px',
};

const RegisterScreen = ({ setScreen, setOpenInfo }) => {
  const [phase, setPhase] = useState(1);
  const [nombre, setNombre] = useState('');
  const [correo, setCorreo] = useState('');
  const [telefono, setTelefono] = useState('');
  const [curp, setCurp] = useState('');
  const [pass, setPass] = useState('');
  const [confirmPass, setConfirmPass] = useState('');
  const [nombreContacto, setNombreContacto] = useState('');
  const [correoContacto, setCorreoContacto] = useState('');
  const [telefonoContacto, setTelefonoContacto] = useState('');
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState('');


  const validateForm = (n) => {
    if (n === 1) {
      // nombre should exists
      if (nombre.length <= 0) {
        setMsg('El nombre es un campo obligatorio.');
        setOpen(true);
        return false;
      }
      if (!EMAILREGEX.test(correo)) {
        setMsg('Ingresa un correo válido.');
        setOpen(true);
        return false;
      }
      if (`${telefono}`.length < 7 || `${telefono}`.length > 10) {
        setMsg('El telefono debe ser un número de 7 a 10 dígitos');
        setOpen(true);
        return false;
      }
      if (`${curp}`.length < 18 || `${curp}`.length > 18) {
        setMsg('La CURP debe ser de 18 caracteres');
        setOpen(true);
        return false;
      }
      if (pass.length <= 0) {
        setMsg('Ingresa una contraseña');
        setOpen(true);
        return false;
      }
      if (pass !== confirmPass) {
        setMsg('Las contraseñas no coinciden');
        setOpen(true);
        return false;
      }

      return true;
    }
    if (n === 2) {
      if (nombreContacto.length <= 0) {
        setMsg('El nombre es un campo obligatorio.');
        setOpen(true);
        return false;
      }
      if (!EMAILREGEX.test(correoContacto)) {
        setMsg('Ingresa un correo válido.');
        setOpen(true);
        return false;
      }
      if (`${telefonoContacto}`.length < 7 || `${telefonoContacto}`.length > 10) {
        setMsg('El telefono debe ser un número de 7 a 10 dígitos');
        setOpen(true);
        return false;
      }
      return true;
    }
  };

  const nextPhase = () => {
    if (validateForm(1)) { // phase 1
      setPhase(2);
    }
  };

  const register = async () => {
    if (validateForm(2)) {
      const newUser = {
        eliminado: false,
        nombre,
        telefono,
        curp,
        correo,
        pass,
        nombreContacto,
        correoContacto,
        telefonoContacto,
      };
      axios.defaults.baseURL = SECRETS.SERVER;
      const res = await axios
        .post('/usuario', newUser)
        .catch((e) => {
          setMsg('Ocurrió un error. Intenta de nuevo más tarde');
          setOpen(true);
          console.error(e);
        });
      console.log(res);
      if (res && res.status === 200) {
        setScreen(0);
      } else {
        setMsg('Ocurrió un error. Intenta de nuevo más tarde');
        setOpen(true);
      }
    }
  };

  return (
    <div style={container}>
      {phase === 1 && (
        <div style={phase1}>
          <div style={backStyle} onClick={() => setScreen(0)}>
            <img src={back} alt="Atrás" />
          </div>
          <div style={header}>Regístrate</div>
          <div>
            <input
              type="text"
              placeholder="Nombre completo"
              style={inputStyle}
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
            />
            <input
              type="email"
              placeholder="Correo"
              style={inputStyle}
              value={correo}
              onChange={(e) => setCorreo(e.target.value)}
            />
            <input
              type="number"
              placeholder="Teléfono"
              style={inputStyle}
              value={telefono}
              onChange={(e) => setTelefono(e.target.value)}
            />
            <input
              type="text"
              placeholder="CURP"
              style={inputStyle}
              value={curp}
              onChange={(e) => setCurp(e.target.value)}
            />
            <input
              type="password"
              placeholder="Contraseña"
              style={inputStyle}
              value={pass}
              onChange={(e) => setPass(e.target.value)}
            />
            <input
              type="password"
              placeholder="Confirmar contraseña"
              style={inputStyle}
              value={confirmPass}
              onChange={(e) => setConfirmPass(e.target.value)}
            />
          </div>
          <div style={bottomContainer}>
            <div style={buttonStyle} onClick={nextPhase}>
              Siguiente &gt;
            </div>
            <div style={footer} onClick={() => setOpenInfo(true)}>
              ¿Cómo funciona?
            </div>
          </div>
        </div>
      )}
      {phase === 2 && (
        <div style={phase2}>
          <div style={backStyle} onClick={() => setPhase(1)}>
            <img src={back} alt="Atrás" />
          </div>
          <div style={header}>Contacto de Emergencia</div>
          <div style={miniContainer}>
            <input
              type="text"
              placeholder="Nombre completo"
              style={inputStyle}
              value={nombreContacto}
              onChange={(e) => setNombreContacto(e.target.value)}
            />
            <input
              type="email"
              placeholder="Correo"
              style={inputStyle}
              value={correoContacto}
              onChange={(e) => setCorreoContacto(e.target.value)}
            />
            <input
              type="number"
              placeholder="Teléfono"
              style={inputStyle}
              value={telefonoContacto}
              onChange={(e) => setTelefonoContacto(e.target.value)}
            />
          </div>
          <div style={bottomContainer}>
            <div style={buttonStyle} onClick={register}>
              Registrarse
            </div>
            <div style={footer} onClick={() => setOpenInfo(true)}>
              ¿Cómo funciona?
            </div>
          </div>
        </div>
      )}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
        message={msg}
      />
    </div>
  )
};

export default RegisterScreen;