import React, { useState } from 'react';
import * as Axios from 'axios';

import starOn from './estrella_on.svg';
import starOff from './estrella_off.svg';

import SECRETS from './secrets';

const axios = Axios.create({
  baseURL: SECRETS.SERVER,
});

const container = {
  height: '100%',
  width: '100%',
  backgroundColor: '#fff',
  color: '#454545',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const estrellasStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
};

const buttonStyle = {
  backgroundColor: '#f4822a',
  color: 'white',
  borderRadius: '10px',
  fontSize: '20px',
  textAlign: 'center',
  height: '55px',
  minHeight: '55px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  boxShadow: '0px 6px 0px #d1600a',
  width: '100%',
  cursor: 'pointer',
};

const headerStyle = {
  fontSize: '30px',
  marginBottom: '30px',
};

const halfBoxStyle = {
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  width: '80%',
  alignItems: 'center',
};

const halfBoxBottomStyle = {
  ...halfBoxStyle,
  justifyContent: 'center',
};

const starStyle = {
  width: '15%',
};

const RateScreen = ({ setScreen }) => {
  const [stars, setStars] = useState(1);

  const next = async () => {
    const token = localStorage.getItem('token');
    const idAlerta = localStorage.getItem('idAlerta');
    axios.defaults.baseURL = SECRETS.SERVER;
    axios.defaults.headers.common.Authorization = `bearer ${token}`;
    await axios
      .patch('/alerta', {
        id: parseInt(idAlerta),
        estrellas: stars,
      })
      .catch(e => console.error(e));
    setScreen(4);
  };

  return (
    <div style={container}>
      <div style={halfBoxStyle}>
        <div style={headerStyle}>
          Califica el servicio
        </div>
        <div style={estrellasStyle}>
          <img
            style={starStyle}
            src={stars > 0 ? starOn : starOff}
            alt="1 estrella"
            onClick={() => setStars(1)}
          />
          <img
            style={starStyle}
            src={stars > 1 ? starOn : starOff}
            alt="2 estrellas"
            onClick={() => setStars(2)}
          />
          <img
            style={starStyle}
            src={stars > 2 ? starOn : starOff}
            alt="3 estrellas"
            onClick={() => setStars(3)}
          />
          <img
          style={starStyle}
            src={stars > 3 ? starOn : starOff}
            alt="4 estrellas"
            onClick={() => setStars(4)}
          />
          <img
            style={starStyle}
            src={stars > 4 ? starOn : starOff}
            alt="5 estrellas"
            onClick={() => setStars(5)}
          />
        </div>
      </div>
      <div style={halfBoxBottomStyle}>
        <div
          style={buttonStyle}
          onClick={next}
        >
          Siguiente
        </div>
      </div>
    </div>
  )
};

export default RateScreen;