import React, { useState } from 'react';
import * as Axios from 'axios';

import SECRETS from './secrets';

const axios = Axios.create({
  baseURL: SECRETS.SERVER,
});

const container = {
  height: '100%',
  width: '100%',
  backgroundColor: '#fff',
  color: '#454545',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  alignItems: 'center',
};

const headerBox = {
  width: '80%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const header = {
  fontSize: '38px'
};

const subheader = {
  fontSize: '20px',
  textAlign: 'center',
};

const checkedStyle = {
  backgroundColor: '#f4822a',
  width: '26px',
  height: '26px',
  borderRadius: '50%',
  marginRight: '40px',
};

const uncheckedStyle = {
  ...checkedStyle,
  width: '18px',
  height: '18px',
  backgroundColor: '#fff',
  border: '#848484 4px solid',
};

const optionsBox = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  width: '70%',
  height: '50%',
};

const optionStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-begin',
  alignItems: 'center',
  fontSize: '18px',
};

const footerBox = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  alignItems: 'center',
  width: '80%',
};

const buttonStyle = {
  backgroundColor: '#f4822a',
  color: 'white',
  borderRadius: '10px',
  fontSize: '20px',
  textAlign: 'center',
  height: '55px',
  minHeight: '55px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  boxShadow: '0px 6px 0px #d1600a',
  width: '100%',
  cursor: 'pointer',
};

const ExperienceScreen = ({ setScreen }) => {
  const [opcion1, setOpcion1] = useState(false);
  const [opcion2, setOpcion2] = useState(true);
  const [opcion3, setOpcion3] = useState(true);
  const [opcion4, setOpcion4] = useState(true);

  const next = async () => {
    const token = localStorage.getItem('token');
    const idAlerta = localStorage.getItem('idAlerta');
    axios.defaults.baseURL = SECRETS.SERVER;
    axios.defaults.headers.common.Authorization = `bearer ${token}`;
    await axios
      .patch('/alerta', {
        id: parseInt(idAlerta),
        opcion1,
        opcion2,
        opcion3,
        opcion4,
      })
      .catch(e => console.error(e));
    localStorage.removeItem('idAlerta');
    setScreen(2);
  };

  return (
    <div style={container}>
      <div style={headerBox}>
        <div style={header}>Tu experiencia</div>
        <div style={subheader}>De acuerdo a tu experiencia elige&nbsp;las opciones verdaderas</div>
      </div>
      <div style={optionsBox}>
        <div style={optionStyle} onClick={() => setOpcion1(!opcion1)}>
          <div
            style={opcion1 ? checkedStyle : uncheckedStyle}
          />
          <div>Llegaron rápido</div>
        </div>
        <div style={optionStyle} onClick={() => setOpcion2(!opcion2)}>
          <div
            style={opcion2 ? checkedStyle : uncheckedStyle}
          />
          <div>Atendieron mi solicitud</div>
        </div>
        <div style={optionStyle} onClick={() => setOpcion3(!opcion3)}>
          <div
            style={opcion3 ? checkedStyle : uncheckedStyle}
          />
          <div>Fueron respetuosos</div>
        </div>
        <div style={optionStyle} onClick={() => setOpcion4(!opcion4)}>
          <div
            style={opcion4 ? checkedStyle : uncheckedStyle}
          />
          <div>Me siento segura</div>
        </div>
      </div>
      <div style={footerBox}>
        <div
          style={buttonStyle}
          onClick={next}
        >
          Enviar
        </div>
      </div>
    </div>
  )
};

export default ExperienceScreen;