import React from 'react';

const shadeStyle = {
  height: '100%',
  width: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  backgroundColor: 'rgba(0,0,0,0.3)',
};

const popupStyle = {
  height: '80%',
  width: '80vw',
  position: 'absolute',
  top: '40px',
  left: '6vw',
  backgroundColor: '#fff',
  overflow: 'scroll',
  padding: '4vw',
  borderRadius: '15px',
  margin: 'auto auto',
  color: '#454545'
};

const buttonStyle = {
  backgroundColor: '#f4822a',
  color: 'white',
  borderRadius: '10px',
  fontSize: '20px',
  textAlign: 'center',
  height: '55px',
  minHeight: '55px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  boxShadow: '0px 6px 0px #d1600a',
  width: '100%',
  cursor: 'pointer',
  marginBottom: '25px',
};

const justifyText = {
  textAlign: 'justify',
};

const header = {
  fontSize: '30px',
  textAlign: 'center',
};

const MoreInfo = ({ close }) => {
  return (
    <div>
      <div style={shadeStyle} onClick={close} />
      <div style={popupStyle}>
        <p style={header}>¿Cómo funciona?</p>

        <p><b>UBICACIÓN GPS</b></p>
        <p style={justifyText}>
          La aplicación requiere de acceso al GPS del celular para poder enviar su ubicación a la <i>Central de Emergencias del Municipo de Morelia</i>.
        </p>
        <p style={justifyText}>
          Al ingresar la aplicación, ésta pedirá permiso para acceder a su ubicación, sin embargo, su ubicación no será enviada si no ha presionado el botón naranja de emergencias.
        </p>
        <p><b>EMERGENCIAS</b></p>
        <p style={justifyText}>
          En caso de alguna emergencia presione el botón naranja, en ese momento se enviará una patrulla a la ubicación que brindará la aplicación.
        </p>
        <p style={justifyText}>
          Su ubicación se enviará continuamente siempre y cuando la aplicación esté activa.
        </p>
        <p style={justifyText}>
          <i>*Si se llega a mover del sitio donde se oprimió el botón, mantenga la app abierta para que se actualice dicha ubicación</i>
        </p>

        <p><b>CANCELAR ALERTA</b></p>
        <p style={justifyText}>Si se presionó el botón de emergencias por equivocación, en la pantalla siguiente aparece un botón de cancelación, la cual evitará que la patrulla que se envía llegue hasta su ubicación.</p>

        <p><b>AL TERMINAR EL SERVICIO</b></p>
        <p style={justifyText}>Al terminar la atención dada por parte de <i>La Comisión Municipal de Seguridad Ciudadana</i> usted debe calificar la eficiencia del servicio contestando 4 sencillas preguntas.</p>

        <p><b>RED SOCIAL</b></p>
        <p style={justifyText}>Si requiere que sus familiares estén al tanto de su situación, se le comunicará al contacto que usted ingrese al momento de registrarse.</p>
        <div
          style={buttonStyle}
          onClick={close}
        >
          Cerrar
        </div>
      </div>
    </div>
  );
};

export default MoreInfo;