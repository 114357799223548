import React, { useState, useEffect } from 'react';
import * as Axios from 'axios';
import { Snackbar } from '@material-ui/core';
import io from 'socket.io-client';

import logo from './logo.svg';
import alerta from './alerta_1.svg';
import menu from './menu.svg';
import divisor from './divider.svg';

import SECRETS from './secrets';

const axios = Axios.create({
  baseURL: SECRETS.SERVER,
});

const EMAILREGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const boxMorelia = {
  upLat: 19.82,
  downLat: 19.63,
  leftLng: -101.33,
  rightLng: -101.1
}

const container = {
  height: '100%',
  width: '100%',
  backgroundColor: '#fff',
  color: '#454545',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-around',
};

const menuButton = {
  position: 'absolute',
  top: '15px',
  left: '15px',
  width: '25px',
};

const coverStyle = {
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0,0,0,0.5)',
  zIndex: 999,
};

const menuStyle = {
  position: 'absolute',
  top: '0',
  left: '0',
  width: '75%',
  height: '100%',
  zIndex: 1000,
  backgroundColor: '#fff',
  borderTopRightRadius: '25px',
  borderBottomRightRadius: '25px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

const menuContainer = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  height: '90%',
  fontSize: '20px',
  color: '#454545',
  textAlign: 'center',
};

const menuDivider = {
  height: '3px',
  marginBottom: '10px',
};

const textSpacing = {
  marginTop: '20px',
};

const menuSecondaryTextColor = {
  ...textSpacing,
  color: '#595959',
};

const subheader = {
  ...menuSecondaryTextColor,
  marginBottom: '40px',
};

const smallText = {
  ...textSpacing,
  fontSize: '16px',
};

const menuFooter = {
  fontSize: '14px',
  textDecoration: 'underline',
  color: '#454545',
};

const menuButtonStyle = {
  backgroundColor: '#f4822a',
  color: 'white',
  borderRadius: '10px',
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  boxShadow: '0px 6px 0px #d1600a',
  cursor: 'pointer',
  width: '100%',
  height: '40px',
  minHeight: '40px',
};

const menuSaveButtonStyle = {
  ...menuButtonStyle,
  width: '80%',
};

const menuInputLabelStyle = {
  textAlign: 'left',
};

const menuInputStyle = {
  borderTop: 0,
  borderLeft: 0,
  borderRight: 0,
  paddingTop: '5px',
  marginBottom: '10px',
};

const closeIconStyle = {
  fontSize: '25px',
  alignSelf: 'flex-end',
  marginRight: '15px',
  height: '27px',
  width: '27px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const container2 = {
  ...container,
  backgroundColor: '#c296c4',
  background: 'linear-gradient(#c296c4 45%, #eaa99e)',
  color: '#ffffffcc',
  zIndex: 0,
}

const buttonStyle = {
  // backgroundColor: '#e83479',
  backgroundColor: '#f4822a',
  boxShadow: '0px 6px 0px #d1600a',
  color: 'white',
  borderRadius: '50%',
  fontSize: '155px',
  textAlign: 'center',
  height: '200px',
  width: '200px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  // boxShadow: '0px 8px 0px #af0047',
  cursor: 'pointer',
};

const buttonStyle2 = {
  ...buttonStyle,
  backgroundColor: '#111',
  border: '8px solid #4b3843',
  boxShadow: '#7d5e6f 0px 0px 0px 5px',
};

const text1 = {
  fontSize: '26px',
  marginBottom: '20px',
};

const text2 = {
  fontSize: '18px',
  marginBottom: '40px',
};

const mainBox = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'center',
  flexGrow: 2,
};

const footer = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'center',
  textDecoration: 'underline',
  flexGrow: 2,
  marginBottom: '40px',
  color: '#333',
};

const cancelBox = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'center',
  width: '100%',
  flexGrow: 1,
};

const cancelButton = {
  backgroundColor: '#515151',
  color: 'white',
  borderRadius: '10px',
  fontSize: '20px',
  textAlign: 'center',
  height: '50px',
  width: '70%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  boxShadow: '0px 6px 0px #454545',
};

const logoStyle = {
  width: '60%',
  height: '65%',
};

const MainScreen = ({ setScreen, setOpenInfo }) => {
  const [socket, setSocket] = useState(null);
  const [phase, setPhase] = useState(1);
  const [menuOpen, setMenuOpen] = useState(false);
  const [idUsuario, setIdUsuario] = useState(0);
  const [estado, setEstado] = useState(null);
  const [nombre, setNombre] = useState('');
  const [correo, setCorreo] = useState('');
  const [telefono, setTelefono] = useState('');
  const [curp, setCurp] = useState('');
  const [nombreContacto, setNombreContacto] = useState('');
  const [correoContacto, setCorreoContacto] = useState('');
  const [telefonoContacto, setTelefonoContacto] = useState('');
  const [idInterval, setIdInterval] = useState(0);
  const [editMenu, setEditMenu] = useState(false);
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      axios.defaults.baseURL = SECRETS.SERVER;
      axios.defaults.headers.common.Authorization = `bearer ${token}`;
      axios
        .get('/usuario')
        .then((resp) => {
          const u = resp.data;
          setIdUsuario(u.id);
          setNombre(u.nombre);
          setCorreo(u.correo);
          setTelefono(u.telefono);
          setCurp(u.curp);
          setNombreContacto(u.nombreContacto);
          setCorreoContacto(u.correoContacto);
          setTelefonoContacto(u.telefonoContacto);
        })
        .catch(e => console.error(e));
      if (navigator.geolocation) {
        // access to gps granted
        navigator.geolocation.getCurrentPosition(() => {});
      }
    }
  }, []);

  useEffect(() => {
    if( estado ) {
      const alertaId = localStorage.getItem('idAlerta');
      switch (estado) {
        case 'ACTIVA':
          const emitLocation = () => {
            navigator.geolocation.getCurrentPosition((position) => {
              const { latitude: lat, longitude: lng } = position.coords;
              socket.emit('emergency-location', {
                idUsuario,
                estado,
                nombre,
                correo,
                telefono,
                curp,
                nombreContacto,
                correoContacto,
                telefonoContacto,
                sos: true,
                lat,
                lng,
                fecha: Date.now(),
                id: alertaId,
              });
            });
          };
          const interval = setInterval(emitLocation, 1000 * 10);
          setIdInterval(interval);
          break;
  
        case 'CANCELADA':
        case 'CERRADA':
          clearInterval(idInterval)
      }
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude: lat, longitude: lng } = position.coords;
        socket.emit('emergency-location', {
          idUsuario,
          estado,
          nombre,
          correo,
          telefono,
          curp,
          nombreContacto,
          correoContacto,
          telefonoContacto,
          lat,
          lng,
          fecha: Date.now(),
          id: alertaId,
        });
      });
    }
    
  }, [estado]);

  const validateForm = () => {
  
    // nombre should exists
    if (nombre.length <= 0) {
      setMsg('El nombre es un campo obligatorio.');
      setOpen(true);
      return false;
    }
    if (!EMAILREGEX.test(correo)) {
      setMsg('Ingresa un correo válido.');
      setOpen(true);
      return false;
    }
    if (`${telefono}`.length < 7 || `${telefono}`.length > 10) {
      setMsg('El telefono debe ser un número de 7 a 10 dígitos');
      setOpen(true);
      return false;
    }
    // if (pass.length <= 0) {
    //   setMsg('Ingresa una contraseña');
    //   setOpen(true);
    //   return false;
    // }
    // if (pass !== confirmPass) {
    //   setMsg('Las contraseñas no coinciden');
    //   setOpen(true);
    //   return false;
    // }
    if (nombreContacto.length <= 0) {
      setMsg('El nombre es un campo obligatorio.');
      setOpen(true);
      return false;
    }
    if (!EMAILREGEX.test(correoContacto)) {
      setMsg('Ingresa un correo válido.');
      setOpen(true);
      return false;
    }
    if (`${telefonoContacto}`.length < 7 || `${telefonoContacto}`.length > 10) {
      setMsg('El telefono debe ser un número de 7 a 10 dígitos');
      setOpen(true);
      return false;
    }
    return true;
  };

  const sendSos = async (e) => {
    e.preventDefault();
    const innerSocket = io(SECRETS.SOCKETURL);
    setSocket(innerSocket);
    innerSocket.on('connect', async () => {
      innerSocket.emit('room');
      const token = localStorage.getItem('token');
      axios.defaults.baseURL = SECRETS.SERVER;
      axios.defaults.headers.common.Authorization = `bearer ${token}`;
      const { data: alerta } = await axios
        .post('/alerta')
        .catch(e => console.error(e));
      console.log(alerta);
      // save idAlert on localStorage
      localStorage.setItem('idAlerta', alerta.id);
      navigator.geolocation.getCurrentPosition((position) => {
        console.log('got position');
        const { latitude, longitude } = position.coords;
        if(longitude > boxMorelia.leftLng &&
            longitude < boxMorelia.rightLng &&
            latitude > boxMorelia.downLat &&
            latitude < boxMorelia.upLat ) {
              console.log(latitude, longitude);
              setEstado('ACTIVA');
              innerSocket.emit('emergency', {
                idUsuario,
                estado: 'ACTIVA',
                nombre,
                correo,
                telefono,
                curp,
                nombreContacto,
                correoContacto,
                telefonoContacto,
                sos: true,
                lat: latitude,
                lng: longitude,
                fecha: Date.now(),
                id: alerta.id,
              });
              setPhase(2);
        } else {
          setMsg(<div>
            Usted se encuentra fuera de la jurisdicción del municipio de Morelia, por favor marque al 
              <a href="tel:911"> 911 </a> para pedir asistencia</div>);
          setOpen(true);
        }
        
      }, () => {
        console.error('no se pudo acceder al gps');
        setMsg('Para utilizar el botón debe permitir el uso de GPS');
        setOpen(true);
      }, {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      });
      // socket.emit('emergency', {
      //   nombre,
      //   correo,
      //   telefono,
      //   nombreContacto,
      //   correoContacto,
      //   telefonoContacto,
      //   sos: true,
      //   lat: 19.0,
      //   lng: -101.8,
      //   fecha: Date.now(),
      //   id: alerta.id,
      // });

      // const emitLocation = () => {
      //   socket.emit('emergency-location', {
      //     nombre,
      //     correo,
      //     telefono,
      //     nombreContacto,
      //     correoContacto,
      //     telefonoContacto,
      //     sos: true,
      //     lat: 19.0,
      //     lng: -101.8,
      //     fecha: Date.now(),
      //     id: alerta.id,
      //   });
      // };
      // const interval = setInterval(emitLocation, 1000 * 10);
      // setIdInterval(interval);
      // setPhase(2);
    });
  };

  const closeSos = async (e) => {
    e.preventDefault();
    setEstado('CERRADA');
    
    // continue with the process
    const token = localStorage.getItem('token');
    const idAlerta = localStorage.getItem('idAlerta');
    axios.defaults.baseURL = SECRETS.SERVER;
    axios.defaults.headers.common.Authorization = `bearer ${token}`;
    await axios
      .patch('/alerta', {
        id: parseInt(idAlerta),
        fin: Date.now(),
      })
      .catch(e => console.error(e));
    console.log(alerta);
    setScreen(3);
  };

  const cancelSos = (e) => {
    e.preventDefault();
    setEstado('CANCELADA');
    // send that it was cancelled here
    setPhase(1);
  };

  const cerrarSesion = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('id');
    setScreen(0);
  };

  const saveUser = async () => {
    // verify data is correct
    if (validateForm()) {
      const token = localStorage.getItem('token');
      axios.defaults.baseURL = SECRETS.SERVER;
      axios.defaults.headers.common.Authorization = `bearer ${token}`;
      await axios
        .patch('/usuario', {
          nombre,
          correo,
          telefono,
          curp,
          nombreContacto,
          telefonoContacto,
          correoContacto,
        })
        .catch(e => console.error(e));
      setEditMenu(false);
    }
  };

  return (
    <div style={container}>
      <div
        onClick={() => setMenuOpen(true)}
        style={menuButton}
      >
        <img src={menu} alt="menú" />
      </div>
      {menuOpen && (
        <div>
          <div
            style={coverStyle}
            onClick={() => {setMenuOpen(false)}}
          />
          <div style={menuStyle}>
            <div
              style={closeIconStyle}
              onClick={() => setMenuOpen(false)}
            >
              x
            </div>
            <div style={menuContainer}>
              {!editMenu && (
                <div>
                  <div>{nombre}</div>
                  <div style={menuSecondaryTextColor}>{correo}</div>
                  <div style={menuSecondaryTextColor}>{telefono}</div>
                  <div style={menuSecondaryTextColor}>{curp}</div>
                </div>
              )}
              {editMenu && (
                <div style={smallText}>
                  <div style={menuInputLabelStyle}>Nombre:</div>
                  <input
                    style={menuInputStyle}
                    type="text"
                    value={nombre}
                    onChange={(e) => setNombre(e.target.value)}
                  />
                  <div style={menuInputLabelStyle}>Correo:</div>
                  <input
                    style={menuInputStyle}
                    type="email"
                    value={correo}
                    onChange={(e) => setCorreo(e.target.value)}
                  />
                  <div style={menuInputLabelStyle}>Teléfono:</div>
                  <input
                    style={menuInputStyle}
                    type="number"
                    value={telefono}
                    onChange={(e) => setTelefono(e.target.value)}
                  />
                  <div style={menuInputLabelStyle}>CURP:</div>
                  <input
                    style={menuInputStyle}
                    type="text"
                    value={curp}
                    onChange={(e) => setCurp(e.target.value)}
                  />
                </div>
              )}
              <div>
                <img
                  src={divisor}
                  alt="divisor"
                  style={menuDivider}
                />
                <div style={subheader}>
                  Contacto de emergencia
                </div>
                {!editMenu && (
                  <div style={smallText}>
                    <div style={textSpacing}>{nombreContacto}</div>
                    <div style={textSpacing}>{telefonoContacto}</div>
                    <div style={textSpacing}>{correoContacto}</div>
                  </div>
                )}
                {editMenu && (
                  <div style={smallText}>
                    <div style={menuInputLabelStyle}>Nombre:</div>
                    <input
                      style={menuInputStyle}
                      type="text"
                      value={nombreContacto}
                      onChange={(e) => setNombreContacto(e.target.value)}
                    />
                    <div style={menuInputLabelStyle}>Teléfono:</div>
                    <input
                      style={menuInputStyle}
                      type="number"
                      value={telefonoContacto}
                      onChange={(e) => setTelefonoContacto(e.target.value)}
                    />
                    <div style={menuInputLabelStyle}>Correo:</div>
                    <input
                      style={menuInputStyle}
                      type="email"
                      value={correoContacto}
                      onChange={(e) => setCorreoContacto(e.target.value)}
                    />
                  </div>
                )}
              </div>
              {!editMenu && (
                <div style={menuButtonStyle} onClick={() => setEditMenu(true)}>
                  Editar
                </div>
              )}
              {editMenu && (
                <div style={menuSaveButtonStyle} onClick={saveUser}>
                  Guardar
                </div>
              )}
              
              <div>
                <a
                  href="mailto:policia@municipiomorelia.mx"
                  style={menuFooter}
                >
                  Quejas y sugerencias
                </a>
              </div>
              <div>
                <a href="terminos-condiciones.pdf" style={menuFooter} target="_blank" rel="noopener noreferrer">
                  Términos y condiciones del servicio
                </a>
              </div>
              <div>
                <a href="aviso-privacidad.pdf" style={menuFooter} target="_blank" rel="noopener noreferrer">
                  Aviso de privacidad
                </a>
              </div>
              <div style={menuFooter} onClick={cerrarSesion}>
                Salir
              </div>
            </div>
          </div>
        </div>
      )}
      {phase === 1 && (
        <div style={container}>
          <div style={mainBox}>
            <div style={text1}>¿Te sientes en peligro?</div>
            <div style={text2}>Presiona para pedir asistencia</div>
            <div
              style={buttonStyle}
              onClick={sendSos}
            >
              <img
                style={logoStyle}
                src={alerta}
                alt="logo"
                onClick={sendSos}
              />
            </div>
          </div>
          <div style={footer}>
            <div onClick={() => setOpenInfo(true)}>
              ¿Cómo funciona?
            </div>
          </div>
        </div>
      )}
      {phase === 2 && (
        <div style={container2}>
          <div style={mainBox}>
            <div style={text1}>Alerta en progreso</div>
            <div style={text2}>Presiona para finalizar</div>
            <div
              style={buttonStyle2}
              onClick={closeSos}
            >
              <img
                style={logoStyle}
                src={logo}
                alt="logo"
                onClick={closeSos}
              />
            </div>
          </div>
          <div style={cancelBox}>
            <div
              style={cancelButton}
              onClick={cancelSos}
            >
              <span>x</span> Cancelar alerta
            </div>
          </div>
          <div style={footer}>
            <div onClick={() => setOpenInfo(true)}>
              ¿Cómo funciona?
            </div>
          </div>
        </div>
      )}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
        message={msg}
      />
    </div>
  )
};

export default MainScreen;
