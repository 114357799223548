import React, { useState, useEffect } from 'react';

import WelcomeScreen from './WelcomeScreen';
import RegisterScreen from './RegisterScreen';
import MainScreen from './MainScreen';
import RateScreen from './RateScreen';
import ExperienceScreen from './ExperienceScreen';
import MoreInfo from './MoreInfo';

const App = () => {
  const [screen, setScreen] = useState(0);
  const [openInfo, setOpenInfo] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      // if already logged in set screen to Main
      setScreen(2);
    }
  }, []);

  const setToken = ({ id, token }) => {
    localStorage.setItem('id', id);
    localStorage.setItem('token', token);
    setScreen(2);
  };

  const ViewPortContainer = ({ children }) => {
    const [height,] = useState(window.innerHeight);
    const [width,] = useState(window.innerWidth);
    return (
      <div style={{ height, width }}>
        {children}
      </div>
    );
  };

  return (
    <ViewPortContainer>
      {screen === 0 && (
        <WelcomeScreen
          setScreen={setScreen}
          setToken={setToken}
          setOpenInfo={setOpenInfo}
        />
      )}
      {screen === 1 && (
        <RegisterScreen
          setScreen={setScreen}
          setOpenInfo={setOpenInfo}
        />
      )}
      {screen === 2 && (
        <MainScreen
          setScreen={setScreen}
          setOpenInfo={setOpenInfo}
        />
      )}
      {screen === 3 && (
        <RateScreen
          setScreen={setScreen}
        />
      )}
      {screen === 4 && (
        <ExperienceScreen
          setScreen={setScreen}
        />
      )}
      {openInfo && (
        <MoreInfo
          close={() => setOpenInfo(false)}
        />
      )}
    </ViewPortContainer>
  );
}

export default App;
